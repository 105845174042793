<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col class="content-header-left mb-1" cols="12" md="12">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">CRM</h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb style="font-size: 0.9rem">
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="14"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item active> CRM </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <div
        class="col-xl-2 col-md-3 col-sm-6"
        v-for="data in modules"
        :key="data.title"
      >
        <b-col>
          <statistic-card-vertical
            :icon="data.icon"
            :statistic="data.title"
            color="danger"
            :route="data.route"
          />
        </b-col>
      </div>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import AnalyticsSupportTracker from "./AnalyticsSupportTracker.vue";
import { kFormatter } from "@core/utils/filter";

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    kFormatter,
    AnalyticsSupportTracker,
    StatisticCardVertical,

    // InvoiceList,
  },
  data() {
    return {
      data: [
        {
          icon: "UsersIcon",
          title: "Activities",
          route: "/crm/activities",
        },
        {
          icon: "FileTextIcon",
          title: "Task",
          route: "/crm/task",
        },
        {
          icon: "EditIcon",
          title: "Lead",
          route: "/crm/lead",
        },
        {
          icon: "StarIcon",
          title: "Campaign",
          route: "/crm/campaign",
        },
        {
          icon: "DollarSignIcon",
          title: "Sales",
          route: "/crm/sales",
        },
        {
          icon: "rupees",
          title: "Bulk Land Sales",
          route: "/crm/bulklandsales",
        },
        {
          icon: "UserIcon",
          title: "Complaint ",
          route: "/crm/complaint",
        },
        {
          icon: "UserIcon",
          title: "Raw Contacts",
          route: "/crm/rawcontact",
        },
        {
          icon: "UserIcon",
          title: "Customer",
          route: "/crm/customer",
        },
        {
          icon: "DollarSignIcon",
          title: "Expense",
          route: "/crm/expense",
        },
        {
          icon: "DollarSignIcon",
          title: "Site Visit",
          route: "/crm/sitevisit",
        },
        {
          icon: "DollarSignIcon",
          title: "Tree",
          route: "/crm/tree",
        },
      ],
      userData: JSON.parse(localStorage.getItem("userData")),
      userRole: "",
      modules: [],
      sidebarData: "",
      modules:[]
    };
  },
  created() {
    // data
    // this.$http.get('/analytics/data').then((response) => {
    //   this.data = response.data
    // })
  },
  mounted() {
    this.userRole = this.userData.role;
    this.sidebarData = JSON.parse(localStorage.getItem("sidebarData"));
    this.setRoute();
  },
  methods: {
    kFormatter,
    setRoute() {
      this.sidebarData.map((item) => {
        if (item.title == "CRM") {
          this.modules = item.children;
        }
      });
      this.modules.map((item) => {
        this.data.map((inner) => {
          if (inner.title == item.title) {
            item.route = inner.route;
          }
          // if (item.title == "Tree") {
          //   item.route = "/crm/tree";
          // }
        });
      });
      if(this.userData.role == 'channelpartner'){
        this.modules.push({
          icon: "UserIcon",
          title: "Channel Partner",
          route: "/master/crm/channelpartner",
        });
      }
    },
  },
};
</script>
